<template>
  <v-card :color="theme ? '#2F2F2F' :'#E7E5E5'">
    <div class="text-center">
      <i class="fas fa-key fa-3x mt-10 primary-blue-light" :class="{'primary-blue-dark': theme}"></i>
    </div>

    <v-card-title> 
      <v-spacer></v-spacer>
      <span class="font-weight-bold">
        Reset Password
      </span>
      <v-spacer></v-spacer>
    </v-card-title>
      <v-card-text class="text-center">
        <span>
          Set your new password
        </span>

        <div class="mt-5">
          <div
              v-if="success"
              class="green--text"
              role="alert"
          >
            password has been reset you can login now with the new password
          </div>
          <small class="red--text" v-for="err of errors" :key="err.code">
            {{ err.message }}
          </small>
          <v-text-field
          placeholder="New password"
          solo
          class="rounded-lg"
          v-model="password"
          :type="show ? 'text' : 'password'"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show = !show"
          :background-color="theme ? 'black' : ''"
          :error-messages="handleErrors(errors['newPassword1'])"
          >
          </v-text-field>
          <!-- <div v-for="(value, name) in errors" :key="name">
            <div v-if="name === 'newPassword1'">
              <small
                  class="red--text"
                  v-for="err in value"
                  :key="err.code"
              >
                {{ err.message }} ⬆ <br />
              </small>
            </div>
          </div> -->
          <v-text-field
          placeholder="Confirm Password"
          v-model="confirmPassword"
          solo
          class="rounded-lg mt-n6"
          :type="show ? 'text' : 'password'"
          :background-color="theme ? 'black' : ''"
          :error-messages="handleErrors(errors['newPassword2'])"
          >
          </v-text-field>
          <!-- <div v-for="(value, name) in errors" :key="name">
            <div v-if="name === 'newPassword2'">
              <small
                  class="red--text"
                  v-for="err in value"
                  :key="err.code"
              >
                {{ err.message }} ⬆ <br />
              </small>
            </div>
          </div> -->
          <v-btn :color="primaryBlue" block dark :class="{'black--text': theme }" @click="SetNewPass" class="pa-6 rounded-lg" large> Submit</v-btn>
        </div>
      </v-card-text>
      <v-card-actions  class="py-6 nevermind-light" :class="{'nevermind-dark': theme }">
        <v-spacer></v-spacer>
        <span> Never mind.</span> <router-link to='/auth/login' class="ml-2">Sign in </router-link>
        <v-spacer></v-spacer>
      </v-card-actions>
  </v-card>
</template>


<script>
import colorMxn from '@/mixins/color_mixin';
import formMxn from '@/mixins/form_mixin';
import gql from "graphql-tag";

export default {
  name: 'PasswordReset',
  mixins: [colorMxn, formMxn],
  data() {
    return {
      errors : {},
      success : false,
      show: false,
      valid: true,
      password: '',
      confirmPassword: '',
    }
  },
  methods: {
    SetNewPass() {
      let data_ = {
        newPassword1: this.password,
        newPassword2: this.confirmPassword,
        token : this.$route.params.token
      }
      let PASSWORD_QUERY = gql`
        mutation(
          $newPassword1: String!
          $newPassword2: String!
          $token: String!
        ) {
          passwordReset(
            token: $token
            newPassword1: $newPassword1
            newPassword2: $newPassword2
          ) {
            errors
            success
          }
        }
      `;
      this.success = false;
      this.errors = {};
      this.$apollo.mutate({
        mutation: PASSWORD_QUERY,
        variables: data_,
        update: (cache, { data }) => {
          try {
            let errors = data.passwordReset.errors;
            this.success = data.passwordReset.success;
            if (errors) {
              this.errors = errors;
            }
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
  },
}
</script>
